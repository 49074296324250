<template>
  <company-form :showDeleteButton="false" v-on:submit="onSubmit"></company-form>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import Api from "@/services/api";
import CompanyForm from "@/components/forms/CompanyForm.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "SettingsGeneral",
  components: { CompanyForm },
  computed: {
    ...mapGetters({
      activeUser: "activeUser",
      mainCompany: "company/MAIN_COMPANY"
    })
  },
  mounted() {
    // console.log("main:", this.mainCompany);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.SETTINGS") },
      { title: this.$t("ROUTES.COMPANY") }
    ]);
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(company) {
      Api.put("companies/" + this.mainCompany.id, company)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: this.$t("FORM.SUCCESS_TITLE"),
            confirmButtonText: this.$t("FORM.OK"),
            allowOutsideClick: false
          });
        })
        .catch(err => {
          const { status, data } = err.response;
          let message = data?.message || this.$t("FORM.ERROR");
          if ([404, 405, 500].includes(+status)) {
            message = this.$t("FORM.ERROR");
          }
          Swal.fire({
            icon: "error",
            text: message,
            confirmButtonText: this.$t("FORM.OK")
          });
        });
    }
  }
};
</script>
